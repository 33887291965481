import { ChangeEvent, FC } from "react";
import { useFormik } from 'formik'
import { object, string, array } from 'yup'
import { Form, Row, Col, Button } from 'react-bootstrap'
import Datepicker from 'react-datepicker'
import moment from 'moment-timezone'
import clsx from "clsx";
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'


import { ApprovalFormValuesInterface, ApprovalWorklog } from "../ApprovalInterface";

moment.tz.setDefault('America/New_York')

type ApprovalDetailsFormProps = {
    initialValues: ApprovalFormValuesInterface,
    onSubmit: Function,
    isSubmitting: boolean
}

const approvalFormValidationSchema = object().shape({
    request_id: string().required('Required'),
    worklog_id: string().required('Required'),
    signed_off_by: string().required('Required'),
    client_account_id: string().required('Required'),
    patch: string().required('Required'),
    submitted_for_approval: string().required('Required'),
    submitted_for_approval_option: string().required('Required'),
    worklogs: array().of(
        object().shape({
            id: string().required('Required'),
            worklog_starttime: string().required('Required'),
            worklog_endtime: string().required('Required'),
            break_time: string().required('Required'),
        })
    )
})

const ApprovalDetailsForm: FC<ApprovalDetailsFormProps> = ({ initialValues, onSubmit: onSubmitFromProps, isSubmitting = false }) => {
    const formik = useFormik({
        initialValues,
        onSubmit: (values) => onSubmitFromProps(values),
        validationSchema: approvalFormValidationSchema,
        enableReinitialize: true
    })

    const handleWorklogDateChange = (type: string = 'worklog_starttime' || 'worklog_endtime', date: any, index: number) => {
        const newDate = zonedTimeToUtc(new Date(date), 'America/New_York')
        formik.setFieldValue(`worklogs[${index}][${type}]`, String(moment(newDate).unix()))
    }

    const handleBreakTimeChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        if (e.target.checked) {
            formik.setFieldValue(`worklogs[${index}].break_time`, '1')
        } else {
            formik.setFieldValue(`worklogs[${index}].break_time`, '0')
        }
    }

    const setupDate = (date: string): Date => {
        return utcToZonedTime(new Date(Number(date) * 1000), 'America/New_York')
    }

    return (
        <Form onSubmit={formik.handleSubmit} className="w-100">

            <Row>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Signed off by</Form.Label>
                        <Form.Control
                            className="form-control-solid"
                            { ...formik.getFieldProps('signed_off_by') }
                            isInvalid={ formik.touched.signed_off_by && !!formik.errors.signed_off_by }
                        />
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.signed_off_by }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Client approval ID</Form.Label>
                        <Form.Control
                            className="form-control-solid"
                            { ...formik.getFieldProps('client_account_id') }
                            isInvalid={ formik.touched.client_account_id && !!formik.errors.client_account_id }
                        />
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.client_account_id }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <p className="form-label">Worklogs</p>
                </Col>
            </Row>
            {
                formik.values.worklogs && formik.values.worklogs.map((worklog, index) => (
                    <Row key={index}>
                        <Col md="5">
                            <Form.Group className="mb-8">
                                <Form.Label>Job Start Time</Form.Label>
                                <Datepicker
                                    className="form-control form-control-solid"
                                    selected={setupDate(worklog.worklog_starttime)}
                                    minDate={new Date()}
                                    onChange={(date) => handleWorklogDateChange('worklog_starttime', date, index) }
                                    dateFormat="dd-MM-yyyy hh:mm a"
                                    showTimeSelect
                                    timeIntervals={5}
                                />
                                <Form.Control.Feedback
                                    className={
                                        clsx([
                                            {
                                                'd-block': formik.errors.worklogs
                                                    && formik.errors.worklogs[index]
                                                    && (formik.errors.worklogs[index] as ApprovalWorklog).worklog_starttime !== ''
                                            }
                                        ])
                                    }
                                    type="invalid"
                                >
                                    {
                                        formik.errors.worklogs
                                        && formik.errors.worklogs[index]
                                        && (formik.errors.worklogs[index] as ApprovalWorklog).worklog_starttime
                                    }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md="5">
                            <Form.Group className="mb-8">
                                <Form.Label>Job End Time</Form.Label>
                                <Datepicker
                                    className="form-control form-control-solid"
                                    selected={setupDate(worklog.worklog_endtime)}
                                    minDate={setupDate(worklog.worklog_starttime)}
                                    onChange={(date) => handleWorklogDateChange('worklog_endtime', date, index) }
                                    dateFormat="dd-MM-yyyy hh:mm a"
                                    showTimeSelect
                                    timeIntervals={5}
                                />
                                <Form.Control.Feedback
                                    className={
                                        clsx([
                                            {
                                                'd-block': formik.errors.worklogs
                                                    && formik.errors.worklogs[index]
                                                    && (formik.errors.worklogs[index] as ApprovalWorklog).worklog_endtime !== ''
                                            }
                                        ])
                                    }
                                    type="invalid"
                                >
                                    {
                                        formik.errors.worklogs
                                        && formik.errors.worklogs[index]
                                        && (formik.errors.worklogs[index] as ApprovalWorklog).worklog_endtime
                                    }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md="2" className="d-flex align-items-center">
                            <Form.Group>
                                <Form.Check
                                    type="checkbox"
                                    className="form-control-solid"
                                    { ...formik.getFieldProps(`worklogs[${index}].break_time`) }
                                    label="Break Time"
                                    onChange={(e) => handleBreakTimeChange(e, index)}
                                    value={formik.values.worklogs[index].break_time}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                ))
            }
            <Row>
                <Col md="12" className="d-flex justify-content-end">
                    <Button type="submit" disabled={!formik.isValid}>
                        {
                            isSubmitting
                                ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                : "Submit"
                        }
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export { ApprovalDetailsForm }