import { FC } from "react";
import { Form, Row, Col, Button, Card } from 'react-bootstrap'
import { useFormik } from 'formik'
import { object, SchemaOf, string } from 'yup'
import 'yup-phone'

export type IForgotPassword = {
    mail: string,
}

const initialValues: IForgotPassword = {
    mail: '',
}

const validationSchema: SchemaOf<IForgotPassword> = object().shape({
    mail: string().phone("US").required('Required'),
})

type ForgotPasswordFormProps = {
    onSubmit: Function
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ onSubmit: onSubmitFromProps }) => {
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => onSubmitFromProps(values)
    })

    return (
        <Card>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    placeholder="Enter registered phone number"
                                    { ...formik.getFieldProps('mail') }
                                    isInvalid={ formik.touched.mail && !!formik.errors.mail }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Phone must be a valid phone number for region US
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Button type="submit" className="float-start">Recover password</Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    )
}

export { ForgotPasswordForm } 

