import { FC, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { SchemaOf, object, string } from 'yup'
import { Form, Row, Col, Button } from 'react-bootstrap'
import Datepicker from 'react-datepicker'
import moment from 'moment'

import { IWorklog, IRequestOptions } from '../RequestInterface'
import { getRequestOptions } from '../../../services/requestService'
import clsx from 'clsx'

export interface IWorklogFormProps {
    onSubmit: Function
    initialValues: IWorklog
}

const worklogFormValidationSchema: SchemaOf<IWorklog> = object().shape({
    id: string(),
    requestid: string(),
    technician: string().required('Required'),
    technician_name: string(),
    description: string().required('Required'),
    starttime: string().required('Required'),
    endtime: string().required('Required'),
    break_time: string()
})

const WorklogForm: FC<IWorklogFormProps> = ({ onSubmit: onSubmitFromProps, initialValues }) => {
    const [options, setOptions] = useState<IRequestOptions>({ technicians: [] })
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()

    const formik = useFormik({
        initialValues,
        validationSchema: worklogFormValidationSchema,
        onSubmit: (values) => {
            onSubmitFromProps(values)
        },
        enableReinitialize: true
    })

    useEffect(() => {
        getRequestOptions()
            .then(({ data }) => {
                setOptions({
                    technicians: data.technician
                })
            })
    }, [])

    useEffect(() => {
        if (initialValues.starttime !== '') {
            setStartDate(new Date(initialValues.starttime))
        }

        if (initialValues.endtime !== '') {
            setEndDate(new Date(initialValues.endtime))
        }
    }, [initialValues])

    const handleStartDate = (date: any) => {
        setStartDate(date)
        formik.setFieldValue('starttime', moment(date).format('YYYY-MM-DD hh:mm a'))
    }

    const handleEndDate = (date: any) => {
        setEndDate(date)
        formik.setFieldValue('endtime', moment(date).format('YYYY-MM-DD hh:mm a'))
    }

    return (
        <Form onSubmit={formik.handleSubmit} className="w-100">
            <Row>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Technician</Form.Label>
                        <Form.Select
                            className="form-select-solid"
                            { ...formik.getFieldProps('technician') }
                            isInvalid={ formik.touched.technician && !!formik.errors.technician }
                        >
                            <option value="">Select Site</option>
                            { 
                                options.technicians && options.technicians.map(technician => <option value={technician.id} key={technician.id}>{technician.name}</option>)
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.technician }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Description</Form.Label>
                        <Form.Control
                            className="form-control-solid"
                            { ...formik.getFieldProps('description') }
                            isInvalid={ formik.touched.description && !!formik.errors.description }
                        />
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.description }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Job Start Time</Form.Label>
                        <Datepicker
                            className="form-control form-control-solid"
                            selected={startDate}
                            minDate={new Date()}
                            onChange={(date) => handleStartDate(date) }
                            dateFormat="dd-MM-yyyy hh:mm a"
                            showTimeSelect
                            timeIntervals={5}
                        />
                        <Form.Control.Feedback
                            className={
                                clsx([
                                    { 'd-block': formik.errors.starttime !== '' }
                                ])
                            }
                            type="invalid"
                        >
                            { formik.errors.starttime }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Job End Time</Form.Label>
                        <Datepicker
                            className="form-control form-control-solid"
                            selected={endDate}
                            minDate={startDate}
                            onChange={(date) => handleEndDate(date)}
                            dateFormat="dd-MM-yyyy hh:mm a"
                            showTimeSelect
                            timeIntervals={5}
                            readOnly={formik.values.starttime === ''}
                        />
                        <Form.Control.Feedback
                            className={
                                clsx([
                                    { 'd-block': formik.errors.endtime !== '' }
                                ])
                            }
                            type="invalid"
                        >
                            { formik.errors.endtime }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="12" className="d-flex justify-content-end">
                    <Button type="submit" disabled={!formik.isValid}>
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export { WorklogForm }