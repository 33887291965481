import { FC } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormik } from 'formik'

import { ProfileInterface } from "./ProfileInterface";
import { SchemaOf, object, string } from "yup";

type ProfileFormProps = {
    initialValues: ProfileInterface
    onSubmit: Function
}

const profileFormValidationSchema: SchemaOf<Pick<ProfileInterface, "field_full_name">> = object().shape({
    field_full_name: string().required('Required')
})

const ProfileForm: FC<ProfileFormProps> = ({ initialValues, onSubmit: onSubmitFromProps }) => {
    const formik = useFormik({
        initialValues,
        validationSchema: profileFormValidationSchema,
        onSubmit: (values) => onSubmitFromProps(values)
    })

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Row className="mb-8">
                <Col md="6">
                    <Form.Group>
                        <Form.Label>Fullname</Form.Label>
                        <Form.Control
                            {...formik.getFieldProps('field_full_name')}
                        />
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            disabled
                            {...formik.getFieldProps('email')}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-8">
                <Col md="6">
                    <Form.Group>
                        <Form.Label>Account Email</Form.Label>
                        <Form.Control
                            disabled
                            {...formik.getFieldProps('account_email')}
                        />
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group>
                        <Form.Label>Mobile no.</Form.Label>
                        <Form.Control
                            disabled
                            {...formik.getFieldProps('field_mobile_no')}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md="12" className="d-flex justify-content-end">
                    <Button type="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default ProfileForm