import leagcyhttp from "../utils/leagcyhttp"

export const getProfile = () => {
    return leagcyhttp.get('/api/my/info');
}

export const saveProfile = (uid, data) => {
    return leagcyhttp.patch(`/api/peoples/${uid}`, data)
}

export const getTracks = () => {
    return leagcyhttp.get(`/api/user/track/list`)
}

export const getTrackInfo = (id) => {
    return leagcyhttp.get(`/api/user/track/get/${id}`)
}