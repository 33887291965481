import { FC } from 'react'
import {BrowserRouter} from 'react-router-dom'
import { useJsApiLoader } from '@react-google-maps/api'
import { ToastContainer } from 'react-toastify'

import { Routes } from './Routes'

type Props = {
  basename: string
}

const App: FC<Props> = ({basename}) => {
  useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCgGf7r5sHjDn92Z44Z67hXlCP9q4sCh7U",
    libraries: ['drawing', 'places']
  })
  
  return (
    <BrowserRouter basename={basename}>
      <Routes />
      <ToastContainer position='bottom-center' hideProgressBar={true} theme="colored" />
    </BrowserRouter>
  )
}

export {App}
