import {FC, Fragment, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import { LeagcyClientWorklogsTable } from './components/LeagcyClientWorklogsTable'
import { getLeagcyClientWorklogs } from '../../services/requestService'
import {IWorklog} from "./RequestInterface";

const LeagcyWorklogList: FC = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const { requestId }: { requestId: string } = useParams()
    const [worklogs, setWorklogs] = useState<IWorklog[]>([])


    useEffect(() => {
        getLeagcyClientWorklogs(requestId)
            .then((res) => {
                setWorklogs(res.data)
                setIsLoaded(true)
            })
            .catch((err) => console.log('Worklogs fetch error: ', err))
    }, [])

    if(isLoaded === false){
        return (<div className="c-loading">Loading..</div>)
    }


    if(worklogs.length === 0){
        return (<div className="c-loading">No Worklog Yet!</div>)
    }

    return (
        <Fragment>
            <div id="kt_post" className="post d-flex flex-column-fluid">
                <div id="kt_content_container" className="container">
                    <div className="card card-custom">
                        <div className="card-body">
                            <LeagcyClientWorklogsTable data={worklogs} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default LeagcyWorklogList