import { FC, useEffect, useState } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useFormik } from 'formik'
import { string, object } from 'yup'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import Datepicker from 'react-datepicker'
import moment from 'moment'
import clsx from 'clsx'
import { IRequestLeagcy } from '../RequestInterface'
import { useAuth } from '../../../components/AuthProvider'

export interface IRequestFormProps {
  onSubmit: Function,
  initialValues: IRequestLeagcy,
  isSubmitting: boolean
}

const requestFormValidationSchema = object().shape({
  field_subject: string().required('Required'),
  field_description: string().required('Required'),
  field_no_of_flaggers: string().required('Required'),
  field_structure: string(),
  field_location: string(),
  field_cross_street: string(),
  field_city: string(),
  field_municipality: string(),
  field_start_date: string().required('Required'),
  field_end_date: string().required('Required'),
  field_supervisor_name: string().required('Required'),
  field_supervisor_email: string().email('Enter valid email').required('Required'),
  field_pickup_location: string(),
  field_drop_location: string(),
  field_ticket: string(),
  field_account_number:string()

})

const ClientLeagcyRequestFrom: FC<IRequestFormProps> = ({ onSubmit: onSubmitFromProps, initialValues , isSubmitting = false}) => {
  const [mapValue, setMapValue] = useState<any>()
  const [mapValuePickup, setMapValuePickup] = useState<any>()
  const [mapValueDrop, setMapValueDrop] = useState<any>()
  const [jobStartTime, setJobStartTime] = useState<any>()
  const [jobEndTime, setJobEndTime] = useState<any>()
  const [isDispatchJob, setSsDispatchJob] = useState<any>(false)

  const { user: { client_web_supervisors }} = useAuth()

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      onSubmitFromProps(values)
    },
    validationSchema: requestFormValidationSchema
  })

  useEffect(() => {
    /*getRequestOptions()
        .then(({ data }) => {
          setOptions({
            priorities: data.priority,
            statuses: data.status,
            supervisors: data.supervisors,
            technicians: data.technician
          })
          localStorage.setItem('csrf_token', data.csrfToken)
        })*/

    if (initialValues.field_location !== '') {
      setMapValue({ label: initialValues.field_location })
    }

    if (initialValues.field_start_date !== '') {
      setJobStartTime(new Date(initialValues.field_start_date))
    }

    if (initialValues.field_end_date !== '') {
      setJobEndTime(new Date(initialValues.field_end_date))
    }
  }, [])

  const handleMapChange = (data: any) => {
    setMapValue(data)

    formik.setFieldValue('field_location', data.label)

    geocodeByPlaceId(data.value.place_id)
        .then((result) => {
          // set city, state and zip
          result[0].address_components.forEach(address => {
            if (address.types.includes('administrative_area_level_2')) {
              formik.setFieldValue('field_city', address.long_name)
            }

            if (address.types.includes('administrative_area_level_1')) {
              formik.setFieldValue('field_state', address.long_name)
            }

            if (address.types.includes('postal_code')) {
              formik.setFieldValue('field_zip', address.long_name)
            }
          })

          // set lat long
          formik.setFieldValue('location_lat', result[0].geometry.location.lat())
          formik.setFieldValue('location_long', result[0].geometry.location.lng())
        })
        .catch((err) => console.log('GeoCode error: ', err))
  }

  const handleMapChangePickup = (data: any) => {
    setMapValuePickup(data)
    formik.setFieldValue('field_pickup_location', data.label)

    geocodeByPlaceId(data.value.place_id)
        .then((result) => {
          // set city, state and zip
          result[0].address_components.forEach(address => {
            if (address.types.includes('administrative_area_level_2')) {
              formik.setFieldValue('field_city', address.long_name)
            }

            if (address.types.includes('administrative_area_level_1')) {
              formik.setFieldValue('field_state', address.long_name)
            }

            if (address.types.includes('postal_code')) {
              formik.setFieldValue('field_zip', address.long_name)
            }
          })

          // set lat long
          formik.setFieldValue('pickup_location_lat', result[0].geometry.location.lat())
          formik.setFieldValue('pickup_location_long', result[0].geometry.location.lng())
        })
        .catch((err) => console.log('GeoCode error: ', err))
  }

  const handleMapChangeDrop = (data: any) => {
    setMapValueDrop(data)
    formik.setFieldValue('field_drop_location', data.label)
    geocodeByPlaceId(data.value.place_id)
        .then((result) => {
          // set city, state and zip
          result[0].address_components.forEach(address => {
            if (address.types.includes('administrative_area_level_2')) {
              formik.setFieldValue('field_city', address.long_name)
            }

            if (address.types.includes('administrative_area_level_1')) {
              formik.setFieldValue('field_state', address.long_name)
            }

            if (address.types.includes('postal_code')) {
              formik.setFieldValue('field_zip', address.long_name)
            }
          })

          // set lat long
          formik.setFieldValue('drop_location_lat', result[0].geometry.location.lat())
          formik.setFieldValue('drop_location_long', result[0].geometry.location.lng())
        })
        .catch((err) => console.log('GeoCode error: ', err))
  }

  const handleJobStartTime = (date: any) => {
    setJobStartTime(date)
    formik.setFieldValue('field_start_date', moment(date).format('DD MMM YYYY, kk:mm:ss'))
    console.log(moment(date).format('MMM DD YYYY, kk:mm:ss'));
  }

  const handleJobEndTime = (date: any) => {
    setJobEndTime(date)
    formik.setFieldValue('field_end_date', moment(date).format('DD MMM YYYY, kk:mm:ss'))
  }

  const handleJobType = (event: any) => {
    formik.setFieldValue('field_job_type', event.target.value)
    if(event.target.value === 'Delivery'){
      setSsDispatchJob(true)
    }else{
      setSsDispatchJob(false)
    }
  }

  const handleSupervisorChange = (event: any) => {
    const value = event.target.value

    formik.setFieldValue('field_supervisor_name', value)

    const index = client_web_supervisors.map(supervisor => supervisor.name).indexOf(value)

    if (index > -1) {
      formik.setFieldValue('field_supervisor_email', client_web_supervisors[index].email)
    } else {
      formik.setFieldValue('field_supervisor_email', '')
    }
  }

  return (
      <Form onSubmit={formik.handleSubmit} className="w-100">
        <Row>
          <Col md="6">
            <Form.Group className="mb-8">
              <Form.Label className="required">Subject</Form.Label>
              <Form.Control
                  className="form-control-solid"
                  { ...formik.getFieldProps('field_subject') }
                  isInvalid={ formik.touched.field_subject && !!formik.errors.field_subject }
              />
              <Form.Control.Feedback type="invalid">
                { formik.errors.field_subject }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-8">
              <Form.Label className="required">Job Type</Form.Label>
              <Form.Select
                  className="form-control-solid"
                  { ...formik.getFieldProps('field_job_type') }
                  isInvalid={ formik.touched.field_job_type && !!formik.errors.field_job_type }
                  onChange={(type) => handleJobType(type)}
              >
                <option value="Flaggers">Flaggers</option>
                <option value="Transmission">Transmission</option>
                <option value="Parking">Parking</option>
                <option value="Delivery">Delivery</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                { formik.errors.field_job_type }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group className="mb-8">
              <Form.Label className="required">Description</Form.Label>
              <Form.Control
                  className="form-control-solid"
                  { ...formik.getFieldProps('field_description') }
                  isInvalid={ formik.touched.field_description && !!formik.errors.field_description }
              />
              <Form.Control.Feedback type="invalid">
                { formik.errors.field_description }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-8">
              <Form.Label className="required">No. of Flaggers</Form.Label>
              <Form.Control
                  className="form-control-solid"
                  { ...formik.getFieldProps('field_no_of_flaggers') }
                  isInvalid={ formik.touched.field_no_of_flaggers && !!formik.errors.field_no_of_flaggers }
              />
              <Form.Control.Feedback type="invalid">
                { formik.errors.field_no_of_flaggers }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-8">
              <Form.Label className="required">Supervisor Name</Form.Label>
              <Form.Select
                  className="form-control-solid"
                  { ...formik.getFieldProps('field_supervisor_name') }
                  isInvalid={ formik.touched.field_supervisor_name && !!formik.errors.field_supervisor_name }
                  onChange={handleSupervisorChange}
              >
                <option value="">Select Suprvisor</option>
                { client_web_supervisors.map(supervisor => <option value={supervisor.name} key={supervisor.email}>{ supervisor.name }</option>) }
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                { formik.errors.field_supervisor_name }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-8">
              <Form.Label className="required">Supervisor Email</Form.Label>
              <Form.Control
                  className="form-control-solid"
                  { ...formik.getFieldProps('field_supervisor_email') }
                  isInvalid={ formik.touched.field_supervisor_email && !!formik.errors.field_supervisor_email }
              />
              <Form.Control.Feedback type="invalid">
                { formik.errors.field_supervisor_email }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-8">
              <Form.Label className="required">Ticket #</Form.Label>
              <Form.Control
                  className="form-control-solid"
                  { ...formik.getFieldProps('field_ticket') }
                  isInvalid={ formik.touched.field_ticket && !!formik.errors.field_ticket }
              />
              <Form.Control.Feedback type="invalid">
                { formik.errors.field_supervisor_name }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-8">
              <Form.Label className="required">Account #</Form.Label>
              <Form.Control
                  className="form-control-solid"
                  { ...formik.getFieldProps('field_account_number') }
                  isInvalid={ formik.touched.field_account_number && !!formik.errors.field_account_number }
              />
              <Form.Control.Feedback type="invalid">
                { formik.errors.field_account_number }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-8">
              <Form.Label>Structure</Form.Label>
              <Form.Control
                  className="form-control-solid"
                  { ...formik.getFieldProps('field_structure') }
                  isInvalid={ formik.touched.field_structure && !!formik.errors.field_structure }
              />
              <Form.Control.Feedback type="invalid">
                { formik.errors.field_structure }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          {isDispatchJob === false &&
            <Col md="6">
              <Form.Group className="mb-8">
                <Form.Label className="required">Location</Form.Label>
                <GooglePlacesAutocomplete
                    selectProps={{
                      value: mapValue,
                      onChange: handleMapChange
                    }}
                />
                <Form.Control.Feedback
                    className={
                      clsx([
                        {'d-block': formik.errors.field_location !== ''}
                      ])
                    }
                    type="invalid"
                >
                  {formik.errors.field_location}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          }

          {isDispatchJob === false &&
            <Col md="6">
              <Form.Group className="mb-8">
                <Form.Label className="required">City</Form.Label>
                <Form.Control
                    className="form-control-solid"
                    {...formik.getFieldProps('field_city')}
                    isInvalid={formik.touched.field_city && !!formik.errors.field_city}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.field_city}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          }

          {isDispatchJob === false &&
            <Col md="6">
              <Form.Group className="mb-8">
                <Form.Label className="required">Municipality</Form.Label>
                <Form.Control
                    className="form-control-solid"
                    {...formik.getFieldProps('field_municipality')}
                    isInvalid={formik.touched.field_municipality && !!formik.errors.field_municipality}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.field_municipality}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          }

          {isDispatchJob === false &&
            <Col md="6">
              <Form.Group className="mb-8">
                <Form.Label>Zip</Form.Label>
                <Form.Control
                    className="form-control-solid"
                    {...formik.getFieldProps('field_zip')}
                    isInvalid={formik.touched.field_zip && !!formik.errors.field_zip}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.field_zip}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          }

          {isDispatchJob === false &&
            <Col md="6">
              <Form.Group className="mb-8">
                <Form.Label className="required">Cross Street</Form.Label>
                <Form.Control
                    className="form-control-solid"
                    {...formik.getFieldProps('field_cross_street')}
                    isInvalid={formik.touched.field_cross_street && !!formik.errors.field_cross_street}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.field_cross_street}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          }

          {isDispatchJob === false &&
            <Col md="6">
              <Form.Group className="mb-8">
                <Form.Label className="required">State</Form.Label>
                <Form.Select
                    className="form-control-solid"
                    {...formik.getFieldProps('field_state')}
                >
                  <option value="New York">New York</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.field_state}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          }

          {isDispatchJob &&
            <Col md="6">
              <Form.Group className="mb-8">
                <Form.Label className="required">Pickup Location</Form.Label>
                <GooglePlacesAutocomplete
                    selectProps={{
                      value: mapValuePickup,
                      onChange: handleMapChangePickup
                    }}
                />
                <Form.Control.Feedback
                    className={
                      clsx([
                        { 'd-block': formik.errors.field_pickup_location !== '' }
                      ])
                    }
                    type="invalid"
                >
                  { formik.errors.field_pickup_location }
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          }

          {isDispatchJob &&
            <Col md="6">
              <Form.Group className="mb-8">
                <Form.Label className="required">Drop Location</Form.Label>
                <GooglePlacesAutocomplete
                    selectProps={{
                      value: mapValueDrop,
                      onChange: handleMapChangeDrop
                    }}
                />
                <Form.Control.Feedback
                    className={
                      clsx([
                        { 'd-block': formik.errors.field_drop_location !== '' }
                      ])
                    }
                    type="invalid"
                >
                  { formik.errors.field_drop_location }
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          }

          <Col md="6">
            <Form.Group className="mb-8">
              <Form.Label className="required">Job Start Time</Form.Label>
              <Datepicker
                  className="form-control form-control-solid"
                  selected={jobStartTime}
                  minDate={new Date()}
                  onChange={(date) => handleJobStartTime(date) }
                  dateFormat="dd-MM-yyyy hh:mm a"
                  showTimeSelect
                  timeIntervals={5}
              />
              <Form.Control.Feedback
                  className={
                    clsx([
                      { 'd-block': formik.errors.field_start_date !== '' }
                    ])
                  }
                  type="invalid"
              >
                { formik.errors.field_start_date }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-8">
              <Form.Label className="required">Job End Time</Form.Label>
              <Datepicker
                  className="form-control form-control-solid"
                  selected={jobEndTime}
                  minDate={jobStartTime}
                  onChange={(date) => handleJobEndTime(date)}
                  dateFormat="dd-MM-yyyy hh:mm a"
                  readOnly={formik.values.field_start_date === ''}
                  showTimeSelect
                  timeIntervals={5}
              />
              <Form.Control.Feedback
                  className={
                    clsx([
                      { 'd-block': formik.errors.field_end_date !== '' }
                    ])
                  }
                  type="invalid"
              >
                { formik.errors.field_end_date }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Button type="submit" disabled={!formik.isValid}>
              {
                isSubmitting
                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    : "Submit"
              }
            </Button>
          </Col>
        </Row>
      </Form>
  )
}

export { ClientLeagcyRequestFrom }