import axios from 'axios'
import { stringify } from 'querystring'

export const login = (data) => {
    const formData = stringify({
        ...data,
        client_id: "69b9f561-7676-46b8-9816-ac51cc913f65",
        client_secret: "owiEYvSDeuS0owI9jFncfYBVTEPXBiwA1DtpMAlc2dKiX",
        scope: "api",
        grant_type: "password",
        web: "1"
    })

    return axios({
        method: 'post',
        url: 'https://access.workforce7.com/api/oauth/token',
        data: formData,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}

export const forgotPassword = (data) => {
    return axios.post('https://access.workforce7.com/api/user/lost-password', data)
}

export const resetPassword = (data) => {
    return axios.post('https://access.workforce7.com/api/user/lost-password-reset', data)
}