import http from '../utils/http'
import leagcyhttp from '../utils/leagcyhttp'

export const getRequests = () => {
    return http.get('/')
}

export const getMyInfo = () => {
    return leagcyhttp.get('/api/my/info')
}

export const getLeagcyRequests = () => {
    return leagcyhttp.get('/api/requests?type=all')
}

export const getLeagcyClientWorklogs = (requestId) => {
    return leagcyhttp.get(`/api/requests/worklogs?request_id=${requestId}`)
}

export const getLeagcyClientPendingApprovalsWorklogs = () => {
    return leagcyhttp.get(`/api/client/worklogs/pending/approvals`)
}

export const getLeagcyClientApprovedWorklogs = () => {
    return leagcyhttp.get(`/api/me/worklogs`)
}

export const getApprovalDetail = (worklogId) => {
    return leagcyhttp.get(`/api/client/web/worklogs?single_worklog_id=${worklogId}`)
}

export const saveApprovalDetail = (data) => {
    return leagcyhttp.patch('/api/client/web/worklogs', data)
}

export const getPDF = (worklog_id) => {
    var post = {
        "worklog_id":worklog_id
    };
    return leagcyhttp.post('/api/email/me', post)
}

export const getDashboardData = () => {
    return leagcyhttp.get('/api/dashboard/client')
}







export const getRequestOptions = () => {
    return http.get('/bootstrap')
}

export const saveRequest = (data) => {
    return http.post('/', data)
}

export const getRequest = (requestId) => {
    return http.get(`/${requestId}`)
}

export const updateRequest = (requestId, data) => {
    return http.patch(`/${requestId}`, data)
}

export const getWorklogs = (requestId) => {
    return http.get(`/${requestId}/worklog`)
}

export const saveWorklog = (requestId, data) => {
    return http.post(`/${requestId}/worklog`, data)
}

export const updateWorklog = (requestId, worklogId, data) => {
    return http.patch(`/${requestId}/worklog/${worklogId}`, data)
}

export const getWorklog = (requestId, worklogId) => {
    return http.get(`/${requestId}/worklog/${worklogId}`)
}