import { FC, useEffect, useState } from 'react'
import { Form, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useFormik } from 'formik'
import { string, object, array } from 'yup'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import Datepicker from 'react-datepicker'
import moment from 'moment'
import clsx from 'clsx'

import { IRequest, IRequestOptions, IWorklog } from '../RequestInterface'
import { getRequestOptions } from '../../../services/requestService'
import { KTSVG } from '../../../../_metronic/helpers'

export interface IRequestFormProps {
    onSubmit: Function
    initialValues: IRequest
}

const requestFormValidationSchema = object().shape({
    id: string(),
    site: string().required('Required'),
    vendor_ticket: string().required('Required'),
    subject: string().required('Required'),
    description: string().required('Required'),
    no_of_flaggers: string().required('Required'),
    status: string().required('Required'),
    priority: string().required('Required'),
    supervisor_uid: string().required('Required'),
    location: string().required('Required'),
    location_lat: string(),
    location_long: string(),
    cross_street: string().required('Required'),
    city: string().required('Required'),
    state: string().required('Required'),
    zip: string().required('Required'),
    structure: string().required('Required'),
    job_start_time: string().required('Required'),
    job_end_time: string().required('Required'),
    worklogs: array().of(
        object().shape({
            technician: string().required('Required'),
            description: string().required('Required'),
            starttime: string().required('Required'),
            endtime: string().required('Required')
        })
    )
})

type WorklogTime = {
    starttime: any
    endtime: any
}

const RequestForm: FC<IRequestFormProps> = ({ onSubmit: onSubmitFromProps, initialValues }) => {
    const [options, setOptions] = useState<IRequestOptions>({ sites: [], priorities: [], statuses: [], supervisors: [], technicians: [] })
    const [mapValue, setMapValue] = useState<any>()
    const [jobStartTime, setJobStartTime] = useState<any>()
    const [jobEndTime, setJobEndTime] = useState<any>()
    const [worklogTimes, setWorklogTimes] = useState<Array<WorklogTime>>([{ starttime: null, endtime: null }])

    const formik = useFormik({
        initialValues,
        validationSchema: requestFormValidationSchema,
        onSubmit: (values) => {
            onSubmitFromProps(values)
        }
    })

    useEffect(() => {
        getRequestOptions()
            .then(({ data }) => {
                setOptions({
                    sites: data.sites,
                    priorities: data.priority,
                    statuses: data.status,
                    supervisors: data.supervisors,
                    technicians: data.technician
                })
                localStorage.setItem('csrf_token', data.csrfToken)
            })

        if (initialValues.location !== '') {
            setMapValue({ label: initialValues.location })
        }

        if (initialValues.job_start_time !== '') {
            setJobStartTime(new Date(initialValues.job_start_time))
        }

        if (initialValues.job_end_time !== '') {
            setJobEndTime(new Date(initialValues.job_end_time))
        }
    }, [])

    const handleMapChange = (data: any) => {
        setMapValue(data)

        formik.setFieldValue('location', data.label)

        geocodeByPlaceId(data.value.place_id)
            .then((result) => {
                // set city, state and zip
                result[0].address_components.forEach(address => {
                    if (address.types.includes('administrative_area_level_2')) {
                        formik.setFieldValue('city', address.long_name)
                    }

                    if (address.types.includes('administrative_area_level_1')) {
                        formik.setFieldValue('state', address.long_name)
                    }

                    if (address.types.includes('postal_code')) {
                        formik.setFieldValue('zip', address.long_name)
                    }
                })

                // set lat long
                formik.setFieldValue('location_lat', result[0].geometry.location.lat())
                formik.setFieldValue('location_long', result[0].geometry.location.lng())
            })
            .catch((err) => console.log('GeoCode error: ', err))
    }

    const handleJobStartTime = (date: any) => {
        setJobStartTime(date)
        formik.setFieldValue('job_start_time', moment(date).format('YYYY-MM-DD hh:mm a'))
    }

    const handleJobEndTime = (date: any) => {
        setJobEndTime(date)
        formik.setFieldValue('job_end_time', moment(date).format('YYYY-MM-DD hh:mm a'))
    }

    const handleWorklogTimes = (type: string = 'starttime' || 'endtime', date: any, index: number) => {
        setWorklogTimes((worklogTimes) => {
            if (type === 'starttime') {
                worklogTimes[index].starttime = date
            } else if (type === 'endtime') {
                worklogTimes[index].endtime = date
            }

            return worklogTimes
        })

        formik.setFieldValue(`worklogs[${index}].${type}`, moment(date).format('YYYY-MM-DD hh:mm a'))
    }

    const addWorklog = () => {
        const worklogs = [...formik.values.worklogs]

        worklogs.push({
            technician: '',
            description: '',
            starttime: '',
            endtime: ''
        })

        formik.setFieldValue('worklogs', worklogs)

        setWorklogTimes((worklogTimes) => {
            const newWorklogTimes = [...worklogTimes]

            newWorklogTimes.push({
                starttime: null,
                endtime: null
            })

            return newWorklogTimes
        })
    }

    const removeWorklog = (index: number) => {
        const worklogs = [...formik.values.worklogs]

        worklogs.splice(index, 1)

        formik.setFieldValue('worklogs', worklogs)

        setWorklogTimes((worklogTimes) => {
            const newWorklogTimes = [...worklogTimes]

            newWorklogTimes.splice(index, 1)

            return newWorklogTimes
        })
    }

    return (
        <Form onSubmit={formik.handleSubmit} className="w-100">
            <Row>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Site</Form.Label>
                        <Form.Select
                            className="form-select-solid"
                            { ...formik.getFieldProps('site') }
                            isInvalid={ formik.touched.site && !!formik.errors.site }
                        >
                            <option value="">Select Site</option>
                            {
                                options.sites && options.sites.map(site => <option value={site.id} key={site.id}>{site.name}</option>)
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.site }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Subject</Form.Label>
                        <Form.Control
                            className="form-control-solid"
                            { ...formik.getFieldProps('subject') }
                            isInvalid={ formik.touched.subject && !!formik.errors.subject }
                        />
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.subject }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Description</Form.Label>
                        <Form.Control
                            className="form-control-solid"
                            { ...formik.getFieldProps('description') }
                            isInvalid={ formik.touched.description && !!formik.errors.description }
                        />
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.description }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">No. of Flaggers</Form.Label>
                        <Form.Control
                            type="number"
                            className="form-control-solid"
                            { ...formik.getFieldProps('no_of_flaggers') }
                            isInvalid={ formik.touched.no_of_flaggers && !!formik.errors.no_of_flaggers }
                        />
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.no_of_flaggers }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Priority</Form.Label>
                        <Form.Select
                            className="form-select-solid"
                            { ...formik.getFieldProps('priority') }
                            isInvalid={ formik.touched.priority && !!formik.errors.priority }
                        >
                            <option value="">Select Priority</option>
                            {
                                options.priorities && options.priorities.map(priority => <option value={priority.id} key={priority.id}>{priority.name}</option>)
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.priority }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Structure</Form.Label>
                        <Form.Control
                            className="form-control-solid"
                            { ...formik.getFieldProps('structure') }
                            isInvalid={ formik.touched.structure && !!formik.errors.structure }
                        />
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.structure }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Vendor Ticket</Form.Label>
                        <Form.Control
                            className="form-control-solid"
                            { ...formik.getFieldProps('vendor_ticket') }
                            isInvalid={ formik.touched.vendor_ticket && !!formik.errors.vendor_ticket }
                        />
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.vendor_ticket }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Supervisor</Form.Label>
                        <Form.Select
                            className="form-select-solid"
                            { ...formik.getFieldProps('supervisor_uid') }
                            isInvalid={ formik.touched.supervisor_uid && !!formik.errors.supervisor_uid }
                        >
                            <option value="">Select suprvisor</option>
                            {
                                options.supervisors && options.supervisors.map(supervisor => <option value={supervisor.id} key={supervisor.id}>{supervisor.name}</option>)
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.supervisor_uid }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Location</Form.Label>
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyCgGf7r5sHjDn92Z44Z67hXlCP9q4sCh7U"
                            selectProps={{
                                value: mapValue,
                                onChange: handleMapChange
                            }}
                        />
                        <Form.Control.Feedback
                            className={
                                clsx([
                                    { 'd-block': formik.errors.location !== '' }
                                ])
                            }
                            type="invalid"
                        >
                            { formik.errors.location }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">City</Form.Label>
                        <Form.Control
                            className="form-control-solid"
                            { ...formik.getFieldProps('city') }
                            isInvalid={ formik.touched.city && !!formik.errors.city }
                        />
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.city }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Zip</Form.Label>
                        <Form.Control
                            type="number"
                            className="form-control-solid"
                            { ...formik.getFieldProps('zip') }
                            isInvalid={ formik.touched.zip && !!formik.errors.zip }
                        />
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.zip }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Cross Street</Form.Label>
                        <Form.Control
                            className="form-control-solid"
                            { ...formik.getFieldProps('cross_street') }
                            isInvalid={ formik.touched.cross_street && !!formik.errors.cross_street }
                        />
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.cross_street }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">State</Form.Label>
                        <Form.Control
                            className="form-control-solid"
                            { ...formik.getFieldProps('state') }
                            isInvalid={ formik.touched.state && !!formik.errors.state }
                        />
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.state }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Status</Form.Label>
                        <Form.Select
                            className="form-select-solid"
                            { ...formik.getFieldProps('status') }
                            isInvalid={ formik.touched.status && !!formik.errors.status }
                        >
                            <option value="">Select status</option>
                            {
                                options.statuses && options.statuses.map(status => <option value={status.id} key={status.id}>{status.name}</option>)
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            { formik.errors.status }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Job Start Time</Form.Label>
                        <Datepicker
                            className="form-control form-control-solid"
                            selected={jobStartTime}
                            minDate={new Date()}
                            onChange={(date) => handleJobStartTime(date) }
                            dateFormat="dd-MM-yyyy hh:mm a"
                            showTimeSelect
                            timeIntervals={5}
                        />
                        <Form.Control.Feedback
                            className={
                                clsx([
                                    { 'd-block': formik.errors.job_start_time !== '' }
                                ])
                            }
                            type="invalid"
                        >
                            { formik.errors.job_start_time }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group className="mb-8">
                        <Form.Label className="required">Job End Time</Form.Label>
                        <Datepicker
                            className="form-control form-control-solid"
                            selected={jobEndTime}
                            minDate={jobStartTime}
                            onChange={(date) => handleJobEndTime(date)}
                            dateFormat="dd-MM-yyyy hh:mm a"
                            readOnly={formik.values.job_end_time === ''}
                            showTimeSelect
                            timeIntervals={5}
                        />
                        <Form.Control.Feedback
                            className={
                                clsx([
                                    { 'd-block': formik.errors.job_end_time !== '' }
                                ])
                            }
                            type="invalid"
                        >
                            { formik.errors.job_end_time }
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <hr />
                {
                    formik.values.worklogs && (
                        <Col md="12">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h4 className="mb-0">Worklogs</h4>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            Add
                                        </Tooltip>
                                    }
                                >
                                    <button
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                        onClick={addWorklog}
                                        type="button"
                                    >
                                        <span className="svg-icon svg-icon-default">
                                            <KTSVG
                                                path="/media/icons/duotune/general/gen035.svg"
                                            />
                                        </span>
                                    </button>
                                </OverlayTrigger>
                            </div>
                            {
                                formik.values.worklogs.map((worklog, index) => (
                                    <Row key={index}>
                                        <Col md="3">
                                            <Form.Group className="mb-8">
                                                <Form.Label>Technician</Form.Label>
                                                <Form.Select
                                                    className="form-select-solid"
                                                    { ...formik.getFieldProps(`worklogs[${index}].technician`) }
                                                    isInvalid={
                                                        (
                                                            formik.touched.worklogs
                                                            && formik.touched.worklogs[index]
                                                            && formik.touched.worklogs[index].technician
                                                        )
                                                        &&
                                                        (
                                                            formik.errors.worklogs
                                                            && formik.errors.worklogs[index]
                                                            && (formik.errors.worklogs[index] as IWorklog).technician ? true : false
                                                        )
                                                    }
                                                >
                                                    <option value="">Select technician</option>
                                                    {
                                                        options.technicians && options.technicians.map(technician => <option value={technician.id} key={technician.id}>{technician.name}</option>)
                                                    }
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        formik.errors.worklogs
                                                        && formik.errors.worklogs[index]
                                                        && (formik.errors.worklogs[index] as IWorklog).technician
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md="3">
                                            <Form.Group className="mb-8">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control
                                                    className="form-control-solid"
                                                    { ...formik.getFieldProps(`worklogs[${index}].description`) }
                                                    isInvalid={
                                                        (
                                                            formik.touched.worklogs
                                                            && formik.touched.worklogs[index]
                                                            && formik.touched.worklogs[index].description
                                                        )
                                                        &&
                                                        (
                                                            formik.errors.worklogs
                                                            && formik.errors.worklogs[index]
                                                            && (formik.errors.worklogs[index] as IWorklog).description ? true : false
                                                        )
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        formik.errors.worklogs
                                                        && formik.errors.worklogs[index]
                                                        && (formik.errors.worklogs[index] as IWorklog).description
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md="3">
                                            <Form.Group className="mb-8">
                                                <Form.Label>Job Start Time</Form.Label>
                                                <Datepicker
                                                    className="form-control form-control-solid"
                                                    selected={worklogTimes[index].starttime}
                                                    minDate={new Date()}
                                                    onChange={(date) => handleWorklogTimes('starttime', date, index) }
                                                    dateFormat="dd-MM-yyyy hh:mm a"
                                                    showTimeSelect
                                                    timeIntervals={5}
                                                />
                                                <Form.Control.Feedback
                                                    className={
                                                        clsx([
                                                            {
                                                                'd-block': formik.errors.worklogs
                                                                    && formik.errors.worklogs[index]
                                                                    && (formik.errors.worklogs[index] as IWorklog).starttime !== ''
                                                            }
                                                        ])
                                                    }
                                                    type="invalid"
                                                >
                                                    {
                                                        formik.errors.worklogs
                                                        && formik.errors.worklogs[index]
                                                        && (formik.errors.worklogs[index] as IWorklog).starttime
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md="3">
                                            <Form.Group className="d-inline-block w-75">
                                                <Form.Label>Job End Time</Form.Label>
                                                <Datepicker
                                                    className="form-control form-control-solid"
                                                    selected={worklogTimes[index].endtime}
                                                    minDate={worklogTimes[index].starttime}
                                                    onChange={(date) => handleWorklogTimes('endtime', date, index) }
                                                    dateFormat="dd-MM-yyyy hh:mm a"
                                                    showTimeSelect
                                                    timeIntervals={5}
                                                />
                                                <Form.Control.Feedback
                                                    className={
                                                        clsx([
                                                            {
                                                                'd-block': formik.errors.worklogs
                                                                    && formik.errors.worklogs[index]
                                                                    && (formik.errors.worklogs[index] as IWorklog).endtime !== ''
                                                            }
                                                        ])
                                                    }
                                                    type="invalid"
                                                >
                                                    {
                                                        formik.errors.worklogs
                                                        && formik.errors.worklogs[index]
                                                        && (formik.errors.worklogs[index] as IWorklog).endtime
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        Remove
                                                    </Tooltip>
                                                }
                                            >
                                                <button
                                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm float-end mt-10"
                                                    onClick={() => removeWorklog(index)}
                                                    type="button"
                                                >
                                                    <span className="svg-icon svg-icon-default">
                                                        <KTSVG
                                                            path="/media/icons/duotune/general/gen036.svg"
                                                        />
                                                    </span>
                                                </button>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </Col>
                    )
                }
                <Col md="12">
                    <Button type="submit" disabled={!formik.isValid}>
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export { RequestForm }