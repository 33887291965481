import { FC } from "react"
import { Card } from "react-bootstrap"
import { useHistory, useParams } from 'react-router-dom'

import { saveWorklog } from "../../services/requestService"
import { WorklogForm } from './components/WorklogForm'
import { IWorklog } from "./RequestInterface"

const worklogFormInitialValues: IWorklog = {
    description: '',
    technician: '',
    starttime: '',
    endtime: ''
}

const WorklogCreate: FC = () => {
    const history = useHistory()
    const { requestId }: { requestId: string } = useParams()

    const handleSubmit = (values: IWorklog) => {
        saveWorklog(requestId, values)
          .then(() => {
              history.push(`/requests/${requestId}`)
          })
    }

    return (
        <div className="container">
            <Card>
                <Card.Body>
                    <Card.Title>Add Worklog</Card.Title>
                    <WorklogForm
                        initialValues={worklogFormInitialValues}
                        onSubmit={handleSubmit}
                    />
                </Card.Body>
            </Card>
        </div>
    )
}

export default WorklogCreate