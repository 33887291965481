import {FC, useEffect, useState} from 'react'
import { Card, Container } from 'react-bootstrap'

import { getDashboardData } from '../../services/requestService'
import { WorklogStatsInterface } from './WorklogStatsInterface'
import WorklogStatsTable from './components/WorklogStatsTable'

const Dashboard: FC = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [worklogStats, setWorklogStats] = useState<WorklogStatsInterface[] | null>(null)

    useEffect(() => {
        getDashboardData()
            .then((res) => {
                setWorklogStats(res.data)
                setIsLoaded(true)
            })
            .catch((err) => console.log('Error: ', err))
    }, [])

    if(isLoaded === false){
        return (<div className="c-loading">Loading..</div>)
    }

    if (!worklogStats) {
        return null
    }

    if(worklogStats.length < 1){
        return (<div className="c-loading">You do not have anything to review!</div>)
    }

    return (
        <Container>
            <Card>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>Request Statistics</span>
                    </h3>
                </div>
                <Card.Body>
                    <WorklogStatsTable data={worklogStats} />
                </Card.Body>
            </Card>
        </Container>
    )
}

export default Dashboard
