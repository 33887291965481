import React from "react";
import { Route, Redirect } from "react-router-dom";

import { LayoutProvider } from '../../_metronic/layout/core'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import AuthProvider from '../components/AuthProvider'

type PrivateRouteProps = {
    component: React.ComponentType<any>,
    isPrivate?: boolean,
    path: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
    const access_token = localStorage.getItem("access_token")

    if (!access_token) {
      return <Redirect to="/login" />;
    }

    return <Route
      exact
      {...rest}
      render={(props) => (
        <LayoutProvider>
            <AuthProvider>
              <MasterLayout>
                <Component {...props} /> 
              </MasterLayout>
            </AuthProvider>
          </LayoutProvider>
      )}
    />;
}

export { PrivateRoute }
