import React from 'react'
import ReactDOM from 'react-dom'
import {Chart, registerables} from 'chart.js'
import moment from 'moment-timezone'

// Apps
import {App} from './app/App'
import "react-datepicker/dist/react-datepicker.css";
import './_metronic/assets/sass/style.scss'
import 'bootstrap'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

Chart.register(...registerables)

moment.tz.setDefault('America/New_York')

ReactDOM.render(<App basename={PUBLIC_URL} />, document.getElementById('root'))
