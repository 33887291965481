import {FC, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../_metronic/partials'

import { PrivateRoute } from './routes/PrivateRoute'

import {
  Dashboard,
  RequestAdd,
  LeagcyWorklogList,
  ClientLeagcyPendingApprovalsWorklogs,
  ClientLeagcyApprovedWorklogs,
  RequestsList,
  RequestCreate,
  RequestDetails,
  WorklogCreate,
  WorklogEdit,
  Login,
  ApprovalDetails,
  Profile,
  Track,
  ForgotPassword
} from './pages/'

const Routes: FC = () => {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <PrivateRoute path="/dashboard" component={ Dashboard } />
        <PrivateRoute path="/track" component={ Track } />
        <PrivateRoute path="/request/add" component={ RequestAdd } />
        <PrivateRoute path="/request/:requestId/worklogs" component={ LeagcyWorklogList } />
        <PrivateRoute path="/pending-approvals/:worklogId" component={ ApprovalDetails } />
        <PrivateRoute path="/pending-approvals" component={ ClientLeagcyPendingApprovalsWorklogs } />
        <PrivateRoute path="/approved-worklogs" component={ ClientLeagcyApprovedWorklogs } />
        <PrivateRoute path="/requests" component={ RequestsList } />
        <PrivateRoute path="/requests/create" component={ RequestCreate } />
        <PrivateRoute path="/requests/:requestId" component={ RequestDetails } />
        <PrivateRoute path="/requests/:requestId/worklogs/create" component={ WorklogCreate } />
        <PrivateRoute path="/requests/:requestId/worklogs/:worklogId" component={ WorklogEdit } />
        <PrivateRoute path="/profile" component={Profile} />
        <Route exact path="/login" component={ Login } />
        <Route exact path="/forgot-password" component={ ForgotPassword } />
        <Redirect exact from='/' to='/login' />
        <Redirect to='error' />
      </Switch>
    </Suspense>
  )
}

export {Routes}
