import { FC, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { toast } from "react-toastify";

import { LayoutProvider } from '../../../_metronic/layout/core/LayoutProvider'
import { BlankLayout } from '../../../_metronic/layout/BlankLayout'
import { ForgotPasswordForm, IForgotPassword } from './components/ForgotPasswordForm'
import { ResetPasswordForm, IResetPasswordForm } from './components/ResetPasswordForm'
import { forgotPassword, resetPassword } from '../../services/authService'
import {toAbsoluteUrl} from "../../../_metronic/helpers";

const Login: FC = () => {
    const [name, setName] = useState<string | null>(null)

    const history = useHistory()

    const handleForgotPasswordSubmit = (values: IForgotPassword) => {
        forgotPassword(values)
            .then(() => {
                setName(values.mail)
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const handleResetPasswordFormSubmit = (values: IResetPasswordForm) => {
        resetPassword({ ...values, name })
            .then(() => {
                toast.success('Password updated successfully. Please login with your new password.')

                history.push('/login')
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    return (
        <LayoutProvider>
            <BlankLayout>
                <Container>
                    <Row>
                        <Col md="4" className="offset-4 mt-5">
                            <Row>
                                <div className="login-box">
                                    <Col md="12" className="text-center">
                                        <img
                                            src={toAbsoluteUrl('/media/logos/logow7.png')}
                                            className='align-self-end'
                                            alt='Workforce-7'
                                        />
                                    </Col>

                                    <Col md="12">
                                        {
                                            name
                                                ? <ResetPasswordForm onSubmit={handleResetPasswordFormSubmit} />
                                                : <ForgotPasswordForm onSubmit={handleForgotPasswordSubmit} />
                                        }
                                    </Col>

                                    <Col md="12">
                                        <span className="float-end">Already have an account? <Link to='/login'>Login here</Link></span>
                                    </Col>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </BlankLayout>
        </LayoutProvider>
    )
}

export default Login

