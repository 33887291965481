import { FC, useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import ProfileForm from './ProfileForm'
import { getProfile, saveProfile } from "../../services/profileService";
import { ProfileInterface } from "./ProfileInterface";

const Profile: FC = () => {
    const history = useHistory()

    const [profile, setProfile] = useState<ProfileInterface | null>(null)
    
    useEffect(() => {
        getProfile()
            .then((res) => setProfile(res.data))
            .catch((err) => console.log('Error: ', err))
    }, [])
    
    if (!profile) {
        return null
    }
    
    const handleFormSubmit = (values: Pick<ProfileInterface, "field_full_name">) => {
        saveProfile(profile.uid, { 'field_full_name': values.field_full_name })
            .then(() => history.push('/dashboard'))
            .catch((err) => console.log('Error: ', err))
    }

    return (
        <Container>
            <Card>
                <Card.Body>
                    <Card.Title className="mb-10">Profile</Card.Title>
                    <ProfileForm
                        initialValues={profile}
                        onSubmit={handleFormSubmit}
                    />
                </Card.Body>
            </Card>
        </Container>
    )
}

export default Profile