import React, { FC, useContext, useEffect, useState } from "react"
import { User } from "../contexts/User"

import UserContext from "../contexts/UserContext"
import { getMyInfo } from "../services/requestService"

type Props = {
    children: React.ReactNode
}

const AuthProvider: FC<Props> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null)

    useEffect(() => {
        getMyInfo()
            .then(({ data }: { data: User }) => {
                localStorage.setItem('leagcy_csrf_token', data.csrf_token)
                
                setUser(data)
            })
            .catch((err) => console.log(err))
    }, [])

    const refetchUser = async () => {
        try {
            const { data } = await getMyInfo()
            setUser(data)
        } catch (err) {
            return console.log(err)
        }
    }


    if (!user) {
        return null
    }

    return (    
        <UserContext.Provider value={{ user, refetchUser }} >
            { children }
        </UserContext.Provider>
    )
}

export const useAuth = () => useContext(UserContext)

export default AuthProvider