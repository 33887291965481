import { FC, useMemo } from 'react'
import { Column } from 'react-table'

import { IWorklog } from '../RequestInterface'
import { Datatable } from '../../../components/Datatable'
import { KTSVG } from '../../../../_metronic/helpers'
import {getPDF} from "../../../services/requestService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export interface WorklogsTableProps {
    data: IWorklog[]
}

const LeagcyClientApprovedWorklogsTable: FC<WorklogsTableProps> = ({ data}) => {

    const handleFormSubmit = (id: any) => {
        toast('Sending PDF on email!', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
        getPDF(id)
            .then(() => {
                toast('We have sent pdf on email!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            })
    }

    const columns: Column[] = useMemo((): Column[] => ([
        {
            Header: 'ID',
            accessor: 'id_display'
        },
        {
            Header: 'Location',
            accessor: 'request_location'
        },
        {
            Header: 'Start Date & Time',
            accessor: 'starttime_formatted'
        },
        {
            Header: 'End Date & Time',
            accessor: 'endtime_formatted'
        },
        {
            Header: 'Break',
            accessor: 'break_time'
        },
        {
            Header: 'Working Hours',
            accessor: 'work_hours'
        },
        {
            Header: 'Status',
            Cell: ({ row }) => {
                return (
                    <span className="badge badge-light-success">
                        Approved
                    </span>
                )
            }
        },
        {
            Header: 'PDF',
            Cell: ({ row }) => {
                const data: any = row.original
                return (
                    <button
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm float-end"
                        onClick={() => handleFormSubmit(data.worklogid)}
                        type="button"
                    >
                        <span className="svg-icon svg-icon-warning">
                            <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                            />
                        </span>
                    </button>
                )
            }
        },
    ]),[])

    return (
        <div>
            <Datatable columns={columns} data={data} />
            <ToastContainer />
        </div>
    )
}

export { LeagcyClientApprovedWorklogsTable }