import {FC, Fragment, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {ClientLeagcyRequestFrom} from './components/ClientLeagcyRequestFrom'
import {IRequest, IRequestLeagcy} from './RequestInterface'
import {saveRequest} from '../../services/requestService'

const requestFormInitialValues: IRequestLeagcy = {
  field_subject: '',
  field_description: '',
  field_no_of_flaggers: '',
  field_supervisor_name: '',
  field_supervisor_email: '',
  field_structure: '',
  field_location: '',
  field_cross_street: '',
  field_city: '',
  field_state: '',
  field_zip: '',
  field_location_2: '',
  field_cross_street_2: '',
  field_city_2: '',
  field_state_2: '',
  field_start_date: '',
  field_end_date: '',
  field_priority: '',
  field_job_type: '',
  field_transmission_job: '',
  field_pickup_location: '',
  pickup_location_lat: '',
  pickup_location_long: '',
  field_drop_location: '',
  drop_location_lat: '',
  drop_location_long: '',
  field_account_number: '',
  field_ticket: ''
}

const RequestAdd: FC = () => {
  const history = useHistory()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleFormSubmit = (values: IRequest) => {
    setIsSubmitting(true)

    saveRequest(values)
      .then(() => {
        history.push('/requests')
      }).catch((err) => console.log(err))
        .finally(() => setIsSubmitting(false))
  }

  return (
    <Fragment>
      <div id='kt_post' className='post d-flex flex-column-fluid'>
        <div id='kt_content_container' className='container'>
          <div className='card card-custom'>
            <div className="card-body">
              <ClientLeagcyRequestFrom initialValues={requestFormInitialValues} onSubmit={handleFormSubmit} isSubmitting={isSubmitting} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default RequestAdd
