import { FC, useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../components/AuthProvider";

import { getApprovalDetail, saveApprovalDetail } from "../../services/requestService";
import { ApprovalDetailsInterface, ApprovalFormValuesInterface } from "./ApprovalInterface";
import { ApprovalDetailsForm } from './components/ApprovalDetailsForm'

const ApprovalDetails: FC = () => {
    const history = useHistory()

    const { refetchUser } = useAuth()

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const { worklogId } = useParams<{ worklogId: string }>()

    const [approvalDetailsInfo, setApprovalDetailsInfo] = useState<ApprovalDetailsInterface | null>(null)

    const [approvalFormValues, setApprovalFormValues] = useState<ApprovalFormValuesInterface>({
        request_id: "",
        worklog_id: "",
        signed_off_by: "",
        client_account_id: "",
        patch: "2",
        submitted_for_approval: "2",
        submitted_for_approval_option: "email",
        worklogs: []
    })

    useEffect(() => {
        getApprovalDetail(worklogId)
            .then((res) => {
                const approvalDetails: ApprovalDetailsInterface = res.data[0]
                localStorage.setItem('leagcy_csrf_token', res.data.csrf_token)

                setApprovalDetailsInfo(approvalDetails)

                setApprovalFormValues({
                    request_id: approvalDetails.workorderid,
                    worklog_id: approvalDetails.worklogid,
                    signed_off_by: "",
                    client_account_id: "",
                    patch: "2",
                    submitted_for_approval: "2",
                    submitted_for_approval_option: "email",
                    worklogs: approvalDetails.worklogs
                })

            })
            .catch((err) => console.log(err))
    }, [])

    const handleSubmit = (values: ApprovalFormValuesInterface) => {
        setIsSubmitting(true)
        
        saveApprovalDetail(values)
            .then(() => {
                refetchUser()
                    .then(() => {
                        history.push('/pending-approvals')
                    })
            })
            .catch((err) => console.log(err))
            .finally(() => setIsSubmitting(false))
    }

    if(approvalDetailsInfo == null){
        return (<div className="c-loading">Loading..</div>)
    }

    return (
        <Container>
            <Card>
                <Card.Body>
                    <Card.Title className="mb-10">Approval details</Card.Title>
                    <div><label>Request ID:</label> {approvalDetailsInfo.id_display}</div>
                    {/* <div><label>Job Type:</label> {approvalDetailsInfo.job_type}</div> */}
                    <div className="mb-8"><label>Structure:</label> {approvalDetailsInfo.structure}</div>
                    <ApprovalDetailsForm
                        initialValues={approvalFormValues}
                        onSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                    />
                </Card.Body>
            </Card>
        </Container>
    )
}

export default ApprovalDetails