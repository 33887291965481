import {FC, Fragment, useEffect, useState} from 'react'

import { LeagcyClientApprovedWorklogsTable } from './components/LeagcyClientApprovedWorklogsTable'
import {getLeagcyClientApprovedWorklogs} from '../../services/requestService'
import {IWorklog} from "./RequestInterface";

const ClientLeagcyApprovedWorklogs: FC = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [worklogs, setWorklogs] = useState<IWorklog[]>([])
    useEffect(() => {
        getLeagcyClientApprovedWorklogs()
            .then((res) => {
                setWorklogs(res.data)
                setIsLoaded(true)
            })
            .catch((err) => console.log('Worklogs fetch error: ', err))
    }, [])

    if(isLoaded === false){
        return (<div className="c-loading">Loading..</div>)
    }

    return (
        <Fragment>
            <div id="kt_post" className="post d-flex flex-column-fluid">
                <div id="kt_content_container" className="container">
                    <div className="card card-custom">
                        <LeagcyClientApprovedWorklogsTable data={worklogs} />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ClientLeagcyApprovedWorklogs