import React from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'

import {
    ActivityDrawer,
} from '../partials'

const MasterLayout: React.FC = ({children}) => {
    return (
        <PageDataProvider>
            <div className='docs-page d-flex flex-row flex-column-fluid'>
                <AsideDefault/>
                <div className='docs-wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                    <HeaderWrapper/>
                    <div id='kt_docs_content' className='docs-content d-flex flex-column flex-column-fluid'>
                        <Content>{children}</Content>
                    </div>
                </div>
            </div>

            {/* begin:: Drawers */}
            <ActivityDrawer />

            {/* end:: Drawers */}

            <MasterInit/>
        </PageDataProvider>
    )
}

export {MasterLayout}
