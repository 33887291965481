import { createContext } from "react";

import { User } from "./User";

const initialContextValue = {
    user: {} as User,
    refetchUser: () => Promise.resolve()
}

const UserContext = createContext(initialContextValue)

export default UserContext