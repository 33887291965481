import {FC, Fragment, useEffect, useState} from 'react'
import { LeagcyClientPendingWorklogsTable } from './components/LeagcyClientPendingWorklogsTable'
import { getLeagcyClientPendingApprovalsWorklogs } from '../../services/requestService'
import {IWorklog} from "./RequestInterface";

const ClientLeagcyPendingApprovalsWorklogs: FC = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [worklogs, setWorklogs] = useState<IWorklog[]>([])
    useEffect(() => {
        getLeagcyClientPendingApprovalsWorklogs()
            .then((res) => {
                setWorklogs(res.data)
                setIsLoaded(true)
            })
            .catch((err) => console.log('Worklogs fetch error: ', err))
    }, [])

    if(isLoaded === false){
        return (<div className="c-loading">Loading..</div>)
    }

    if(worklogs.length === 0){
        return (<div className="c-loading">You do not have anything pending for approval.</div>)
    }

    return (
        <Fragment>
            <div id="kt_post" className="post d-flex flex-column-fluid">
                <div id="kt_content_container" className="container">
                    <div className="card card-custom">
                        <LeagcyClientPendingWorklogsTable data={worklogs} />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ClientLeagcyPendingApprovalsWorklogs