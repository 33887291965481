import { FC } from "react";
import { Form, Row, Col, Button, Card } from 'react-bootstrap'
import { useFormik } from 'formik'
import { object, SchemaOf, string } from 'yup'

export type ILogin = {
    username: string,
    password: string
}

const initialValues: ILogin = {
    username: '',
    password: ''
}

const validationSchema: SchemaOf<ILogin> = object().shape({
    username: string().required('Required'),
    password: string().required('Required')
})

type LoginFormProps = {
    onSubmit: Function
}

const LoginForm: FC<LoginFormProps> = ({ onSubmit: onSubmitFromProps }) => {
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => onSubmitFromProps(values)
    })

    return (
        <Card>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    placeholder="Enter username"
                                    { ...formik.getFieldProps('username') }
                                    isInvalid={ formik.touched.username && !!formik.errors.username }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { formik.errors.username }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter password"
                                    { ...formik.getFieldProps('password') }
                                    isInvalid={ formik.touched.password && !!formik.errors.password }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { formik.errors.password }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Button type="submit" className="float-start">Login</Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    )
}

export { LoginForm } 

