import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    AxiosError 
} from "axios";

const leagcyhttp: AxiosInstance = axios.create({
    baseURL: 'https://access.workforce7.com/',
    withCredentials: true,
    headers: {
        "Content-Type": "application/json"
    }
})

leagcyhttp.interceptors.request.use((request: AxiosRequestConfig) => {
    const leagcy_csrf_token = localStorage.getItem("leagcy_csrf_token")
    const access_token = localStorage.getItem('access_token')
    if (leagcy_csrf_token) {
        request.headers = {
            "X-CSRF-Token": leagcy_csrf_token
        }
    }
    if (access_token) {
        request.headers.Authorization = `Bearer ${access_token}`;
    }
    return request
})

leagcyhttp.interceptors.response.use(
    (response: AxiosResponse) => {
        return response.data
    },
    (error: AxiosError) => {
        console.log('API Error: ', error.message)
        return Promise.reject(error)
    }
)

export default leagcyhttp
