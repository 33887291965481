import { FC } from 'react'
import { useTable, Column, usePagination } from 'react-table'
import clsx from 'clsx'

type DatatableProps = {
    columns: Column[],
    data: any[]
}

const Datatable: FC<DatatableProps> = ({ columns, data }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        nextPage,
        previousPage,
        state: { pageIndex }
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, usePagination)

    return (
        <div className="w-100">
            <table className="table table-rounded table-striped border gy-7 gs-7 th-table" { ...getTableProps() }>
                <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr
                                className="fw-bold fs-6 text-gray-800  border-gray-200"
                                { ...headerGroup.getHeaderGroupProps() }
                            >
                                {
                                    headerGroup.headers.map(column => (
                                        <th { ...column.getHeaderProps() }>
                                            { column.render('Header') }
                                        </th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody { ...getTableBodyProps() }>
                    {
                        page.map(row => {
                            prepareRow(row)
                            return (
                                <tr { ...row.getRowProps() }>
                                    {
                                        row.cells.map(cell => {
                                            return (
                                                <td { ...cell.getCellProps() }>
                                                    { cell.render('Cell') }
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {
                data.length > 0 && (
                    <div className="d-flex justify-content-end align-items-center">
                        <small className="p-4">Page { pageIndex + 1 } of { pageCount }</small>
                        <ul className="pagination">
                            <li
                                className={clsx([
                                    'page-item previous',
                                    { 'disabled': !canPreviousPage }
                                ])}
                            >
                                <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    <i className="previous"></i>
                                </button>
                            </li>
                            <li
                                className={clsx([
                                    'page-item next',
                                    { 'disabled': !canNextPage }
                                ])}
                            >
                                <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                                    <i className="next"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                )
            }
        </div>
    )
}

export { Datatable }