import { FC, useEffect, useState, Fragment } from "react";
import { Row, Col, Form } from "react-bootstrap";
import {
    GoogleMap,
    DirectionsService,
    DirectionsRenderer,
    Marker,
} from "@react-google-maps/api";

import { Track as TrackInterface } from "./TrackInterface";
import { getTracks, getTrackInfo } from "../../services/profileService";

import UserIcon from '../../icons/user.svg'

const containerStyle = {
    width: "100%",
    height: "70vh"
};

// store details
const store = {
    store_address: "Default Location",
    location: {
        lat: 40.76856,
        lng: -74.23384,
    },
};

const Track: FC = () => {
    const [tracks, setTracks] = useState<TrackInterface[]>([])

    const [currentTrack, setCurrentTrack] = useState<TrackInterface | null>(null)

    const [trackId, setTrackId] = useState("")
    
    const [directions, setDirections] = useState(null)

    useEffect(() => {
        const interval = setInterval(() => {
            if(trackId != ""){
                getTrackInfo(trackId)
                .then(({ data }) => {
                    setCurrentTrack(data)
                })
                .catch((err) => console.log(err));
            }
            
        }, 5000);    
        return () => clearInterval(interval);
      }, [currentTrack]);

    useEffect(() => {
        getTracks()
            .then(({ data }) => {
                setTracks(data)
            })
            .catch((err) => console.log(err))
    }, [])

    const handleRouteChange = (event: any) => {
        const value = event.target.value
        
        if (value && tracks[value]) {
            var assigned_to = tracks[event.target.value]['assigned_to'] 
            setTrackId(assigned_to)
            setDirections(null)
            setCurrentTrack(tracks[event.target.value])
        }
    } 

    const directionsServiceCallback = (response: any) => {
        if (response !== null) {
            if (response.status === "OK") {
                setDirections(response);
            }
        }
    };

    return (
        <div id='kt_post' className='post d-flex flex-column-fluid'>
            <div id='kt_content_container' className='container'>
                <div className='card card-custom'>
                    <div className="card-body">
                        <Form.Group>
                            <Form.Label>Delivery</Form.Label>
                            <Form.Select
                                onChange={handleRouteChange}
                            >
                                <option>Select a Delivery</option>
                                { tracks && tracks.map((track, index) => <option key={index} value={index}>{ track.from_address } - { track.to_address }</option> ) }
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>

                {
                    currentTrack
                        && (
                            <Row className="MapWrap mt-10">
                                <Col className="map-container">
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={store.location}
                                        zoom={14}
                                        clickableIcons={false}
                                    >
                                        <Fragment>
                                            {!directions && (
                                                <DirectionsService
                                                    options={{
                                                        destination: {
                                                            lat: Number(currentTrack.to_lat),
                                                            lng: Number(currentTrack.to_long)
                                                        },
                                                        origin: {
                                                            lat: Number(currentTrack.from_lat),
                                                            lng: Number(currentTrack.from_long)
                                                        },
                                                        travelMode: window.google.maps.TravelMode.DRIVING
                                                    }}
                                                    callback={directionsServiceCallback}
                                                />
                                            )}
                                            

                                            {directions && (
                                                <DirectionsRenderer
                                                    directions={directions}
                                                    options={{
                                                        markerOptions: {
                                                            visible: true,
                                                        },
                                                    }}
                                                />
                                            )}

                                            <Marker
                                                position={{
                                                    lat: Number(currentTrack.current_lat),
                                                    lng: Number(currentTrack.current_long)
                                                }}
                                                icon={UserIcon}
                                            />
                                        </Fragment>
                                    </GoogleMap>
                                </Col>
                            </Row>
                        )
                }
            </div>
        </div>
    );
}

export default Track