import React from 'react'

import {Content} from './components/Content'
import {PageDataProvider} from './core'

const BlankLayout : React.FC = ({children}) => {
    return (
        <PageDataProvider>
            <div className='d-flex flex-row flex-column-fluid'>
                <div className='d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                    <div id='kt_docs_content' className='d-flex flex-column flex-column-fluid'>
                        <Content>{children}</Content>
                    </div>
                </div>
            </div>
        </PageDataProvider>
    )
}

export { BlankLayout }
