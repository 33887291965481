import { FC, Fragment } from 'react'

import {LeagcyRequestsTable} from "../requests/components/LeagcyRequestsTable";

const RequestsList: FC = () => {
    return (
        <Fragment>
            <div id="kt_post" className="post d-flex flex-column-fluid">
                <div id="kt_content_container" className="container">
                    <LeagcyRequestsTable />
                </div>
            </div>
        </Fragment>
    )
}

export default RequestsList