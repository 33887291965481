import { FC, useEffect, useMemo, useState } from 'react'
import { Column } from 'react-table'
import { Link } from 'react-router-dom'

import { Datatable } from '../../../components/Datatable'
import { getLeagcyRequests } from '../../../services/requestService'
import { IRequest } from '../RequestInterface'
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'

const LeagcyRequestsTable: FC = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [requests, setRequests] = useState<IRequest[]>([])

    useEffect(() => {
        getLeagcyRequests()
            .then((res) => {
                setRequests(res.data)
                setIsLoaded(true)
            })
    }, [])

    const columns: Column[] = useMemo((): Column[] => [
        {
            Header: 'Workorder Id',
            accessor: 'workorderid'
        },
        {
            Header: 'Subject',
            accessor: 'subject'
        },
        {
            Header: 'Location',
            accessor: 'location'
        },
        {
            Header: 'Start Time',
            accessor: 'job_start_time'
        },
        {
            Header: 'Status',
            Cell: ({ row }) => {
                const data: any = row.original
                if(data.status === "Closed"){
                    return (
                        <span className="badge badge-light-success">
                            Closed
                        </span>
                    )
                }
                return (
                    <span className="badge badge-light-success">
                            Open
                    </span>
                )
            }
        },
        {
            Header: 'Worklogs',
            Cell: ({ row }) => {
                const data: any = row.original
                return (
                    <Link to={`/request/${data.workorderid}/worklogs`}>
                        <span className="svg-icon svg-icon-warning">
                            <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                            />
                        </span>
                    </Link>
                )
            }
        }
    ], [])


    if(isLoaded === false){
        return (<div className="c-loading">Loading..</div>)
    }

    if(requests.length === 0){
        return (<div className="c-loading">You do not have any request yet!</div>)
    }

    return (
        <Datatable columns={columns} data={requests} />
    )
}

export { LeagcyRequestsTable }