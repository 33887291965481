import { FC, useMemo } from 'react'
import { Column } from 'react-table'
import { Link } from 'react-router-dom'

import { IWorklog } from '../RequestInterface'
import { Datatable } from '../../../components/Datatable'
import { KTSVG } from '../../../../_metronic/helpers'

export interface WorklogsTableProps {
    data: IWorklog[]
}

const LeagcyClientPendingWorklogsTable: FC<WorklogsTableProps> = ({ data }) => {
    const columns: Column[] = useMemo((): Column[] => ([
        {
            Header: 'ID',
            accessor: 'id_display'
        },
        {
            Header: 'Location',
            accessor: 'request_location'
        },
        {
            Header: 'Start Date & Time',
            accessor: 'starttime_formatted'
        },
        {
            Header: 'End Date & Time',
            accessor: 'endtime_formatted'
        },
        {
            Header: 'Break',
            accessor: 'break_time'
        },
        {
            Header: 'Working Hours',
            accessor: 'work_hours'
        },
        {
            Header: 'Approve',
            Cell: ({ row }) => {
                const data: any = row.original
                if(data.worklog_status == "Approved"){
                    return('-')
                }
                return (
                    <Link to={`/pending-approvals/${data.worklogid}`}>
                        <span className="svg-icon svg-icon-warning">
                            <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                            />
                        </span>
                    </Link>
                )
            }
        }
    ]),[])

    return (
        <Datatable columns={columns} data={data} />
    )
}

export { LeagcyClientPendingWorklogsTable }