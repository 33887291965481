import { FC, useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import { useHistory, useParams } from 'react-router-dom'

import { getWorklog, updateWorklog } from "../../services/requestService"
import { WorklogForm } from './components/WorklogForm'
import { IWorklog } from "./RequestInterface"

const WorklogEdit: FC = () => {
    const history = useHistory()
    const { requestId, worklogId }: { requestId: string, worklogId: string } = useParams()
    const [worklog, setWorklog] = useState<IWorklog>({ technician: '', description: '', starttime: '', endtime: '' })

    useEffect(() => {
        getWorklog(requestId, worklogId)
            .then((res) => {
                // console.log(data)
                setWorklog(res.data as IWorklog)
            })
    }, [])

    const handleSubmit = (values: IWorklog) => {
        updateWorklog(requestId, worklogId, values)
            .then(() => {
                history.push(`/requests/${requestId}`)
            })
    }

    return (
        <div className="container">
            <Card>
                <Card.Body>
                    <Card.Title>Edit Worklog</Card.Title>
                    <WorklogForm
                        initialValues={worklog}
                        onSubmit={handleSubmit}
                    />
                </Card.Body>
            </Card>
        </div>
    )
}

export default WorklogEdit