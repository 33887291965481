import { FC } from "react";
import { WorklogStatsInterface } from "../WorklogStatsInterface";

type WorklogStatsTableType = {
    data: WorklogStatsInterface[]
}

var randomClass = [
    'bg-primary','bg-warning', 'bg-danger'
];


const WorklogStatsTable: FC<WorklogStatsTableType> = ({ data }) => {
    return (
        <table className='table table-row-dashed table-row-gray-300 align-middle gy-4'>
            <thead>
            <tr className='fw-bolder'>
                <th className='min-w-150px'>Request</th>
                <th className='min-w-140px'>Info</th>
                <th className='min-w-120px'>Progress</th>
            </tr>
            </thead>
            <tbody>
            {
                data.map(row => (
                    <tr key={row.id}>
                        <td>
                            <div className="d-flex align-items-center mb-8">
                                <span className={`bullet bullet-vertical h-40px right-spacer-10 ${randomClass[Math.floor(Math.random() * randomClass.length)]}`}></span>
                                <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column'>
                                        <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                            {row.label}
                                        </a>
                                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {row.subject}
                          </span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                {row.job_start_time}<br/>
                                {row.job_end_time}
                            </a>
                            <span className='text-muted fw-bold text-muted d-block fs-7'>
                    {row.location}
                  </span>
                        </td>
                        <td className='text-end'>
                            <div className='d-flex flex-column w-100 me-2'>
                                <div className='d-flex flex-stack mb-2'>
                                    <span className='text-muted me-2 fs-7 fw-bold'>{row.task_worked}</span>
                                </div>
                                <div className='progress h-6px w-100'>
                                    <div
                                        className='progress-bar bg-primary'
                                        role='progressbar'
                                        style={{width: row.task_worked}}
                                    ></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    )
}

export default WorklogStatsTable