import { FC, useMemo } from 'react'
import { Column } from 'react-table'
import { Link } from 'react-router-dom'

import { IWorklog } from '../RequestInterface'
import { Datatable } from '../../../components/Datatable'
import { KTSVG } from '../../../../_metronic/helpers'

export interface WorklogsTableProps {
    data: IWorklog[]
}

const WorklogsTable: FC<WorklogsTableProps> = ({ data }) => {
    const columns: Column[] = useMemo((): Column[] => ([
        {
            Header: 'Technician',
            accessor: 'technician_name'
        },
        {
            Header: 'Description',
            accessor: 'description'
        },
        {
            Header: 'Start Time',
            accessor: 'starttime'
        },
        {
            Header: 'End Time',
            accessor: 'endtime'
        },
        {
            Header: 'Actions',
            Cell: ({ row }) => {
                const data = row.original as IWorklog

                return (
                    <Link
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        to={`/requests/${data.requestid}/worklogs/${data.id}`}
                    >
                        <span className="svg-icon svg-icon-primary">
                            <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                            />
                        </span>
                    </Link>
                )
            }
        }
    ]),[])

    return (
        <Datatable columns={columns} data={data} />
    )
}

export { WorklogsTable }