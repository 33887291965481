import {
  ILayout,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
  ILayoutCSSVariables,
} from './LayoutModels'
import {DefaultLayoutConfig} from './DefaultLayoutConfig'

const LAYOUT_CONFIG_KEY = process.env.REACT_APP_BASE_LAYOUT_CONFIG_KEY || 'LayoutConfig'

export function getLayout(): ILayout {
  const ls = localStorage.getItem(LAYOUT_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as ILayout
    } catch (er) {
      console.error(er)
    }
  }
  return DefaultLayoutConfig
}

function setLayout(config: ILayout): void {
  try {
    localStorage.setItem(LAYOUT_CONFIG_KEY, JSON.stringify(config))
  } catch (er) {
    console.error(er)
  }
}

export function getEmptyCssClasses() {
  return {
    header: [],
    headerContainer: [],
    headerMobile: [],
    headerMenu: [],
    aside: [],
    asideMenu: [],
    asideToggle: [],
    toolbar: [],
    toolbarContainer: [],
    content: [],
    contentContainer: [],
    footerContainer: [],
    sidebar: [],
    pageTitle: [],
  }
}

export function getEmptyHTMLAttributes() {
  return {
    asideMenu: new Map(),
    headerMobile: new Map(),
    headerMenu: new Map(),
    headerContainer: new Map(),
    pageTitle: new Map(),
  }
}

export function getEmptyCSSVariables() {
  return {
    body: new Map(),
  }
}

export class LayoutSetup {
  public static isLoaded: boolean = false
  public static config: ILayout = getLayout()
  public static classes: ILayoutCSSClasses = getEmptyCssClasses()
  public static attributes: ILayoutHTMLAttributes = getEmptyHTMLAttributes()
  public static cssVariables: ILayoutCSSVariables = getEmptyCSSVariables()

  private static initCSSClasses(): void {
    LayoutSetup.classes = getEmptyCssClasses()
  }

  private static initHTMLAttributes(): void {
    LayoutSetup.attributes = Object.assign({}, getEmptyHTMLAttributes())
  }

  private static initCSSVariables(): void {
    LayoutSetup.cssVariables = getEmptyCSSVariables()
  }

  private static initLayout(config: ILayout): void {
    Array.from(document.body.attributes).forEach(() => {
      //document.body.removeAttribute(attr.name)
    })
    //document.body.setAttribute('style', '')
    //document.body.setAttribute('id', 'kt_body')
    if (config.main?.body?.backgroundImage) {
      document.body.style.backgroundImage = `url('${config.main.body.backgroundImage}')`
    }

    if (config.main?.body?.class) {
      //document.body.classList.add(config.main.body.class)
    }

    // if (config.loader.display) {
    //   document.body.classList.add('page-loading')
    //   document.body.classList.add('page-loading-enabled')
    // }
  }








  private static initConfig(config: ILayout): void {
    // Init layout
    LayoutSetup.initLayout(config)
    if (config.main?.type !== 'default') {
      return
    }

    // LayoutSetup.initHeader(config.header)
    // LayoutSetup.initPageTitle(config.pageTitle as IPageTitle)
    // LayoutSetup.initToolbar(config.toolbar)
    // LayoutSetup.initContent(config.content)
    // LayoutSetup.initAside(config.aside)
    // LayoutSetup.initFooter(config.footer)
    // LayoutSetup.initAsideMenu(config.aside)
  }

  public static updatePartialConfig(fieldsToUpdate: Partial<ILayout>): ILayout {
    const config = LayoutSetup.config
    const updatedConfig = {...config, ...fieldsToUpdate}
    LayoutSetup.initCSSClasses()
    LayoutSetup.initCSSVariables()
    LayoutSetup.initHTMLAttributes()
    LayoutSetup.isLoaded = false
    LayoutSetup.config = updatedConfig
    LayoutSetup.initConfig(Object.assign({}, updatedConfig))
    LayoutSetup.isLoaded = true // remove loading there
    return updatedConfig
  }

  public static setConfig(config: ILayout): void {
    setLayout(config)
  }

  public static bootstrap = (() => {
    LayoutSetup.updatePartialConfig(LayoutSetup.config)
  })()
}
