import {FC, Fragment} from 'react'
import {useHistory} from 'react-router-dom'

import {RequestForm} from './components/RequestForm'
import {IRequest} from './RequestInterface'
import {saveRequest} from '../../services/requestService'

const requestFormInitialValues: IRequest = {
  site: '',
  vendor_ticket: '',
  subject: '',
  description: '',
  no_of_flaggers: '',
  status: '',
  priority: '',
  supervisor_uid: '',
  location: '',
  cross_street: '',
  city: '',
  state: '',
  zip: '',
  structure: '',
  job_start_time: '',
  job_end_time: '',
  worklogs: []
}

const RequestCreate: FC = () => {
  const history = useHistory()

  const handleFormSubmit = (values: IRequest) => {
    saveRequest(values)
      .then(() => {
        history.push('/requests')
      })
  }

  return (
    <Fragment>
      <div id='kt_post' className='post d-flex flex-column-fluid'>
        <div id='kt_content_container' className='container'>
          <div className='card card-custom'>
            <div className="card-body">
              <RequestForm initialValues={requestFormInitialValues} onSubmit={handleFormSubmit} />  
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default RequestCreate
