import { FC, Fragment, useEffect, useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'

import { IRequest, IWorklog } from './RequestInterface'
import { getRequest, updateRequest, getWorklogs } from '../../services/requestService'
import { RequestForm } from './components/RequestForm'
import { WorklogsTable } from './components/WorklogsTable'

const RequestEdit: FC = () => {
    const { requestId }: { requestId: string } = useParams()
    const history = useHistory()
    const [request, setRequest] = useState<IRequest | null>(null)
    const [worklogs, setWorklogs] = useState<IWorklog[]>([])

    useEffect(() => {
      getRequestDetails()
    }, [])

    const handleFormSubmit = (values: IRequest) => {
        updateRequest(requestId, values)
            .then(() => history.push('/requests'))
            .catch((err) => console.log('Error: ', err))
    }

    const getRequestDetails = () => {
      getRequest(requestId)
        .then((res) => setRequest(res.data))
        .catch((err) => console.log('Request fetch error: ', err))

      getWorklogs(requestId)
        .then((res) => setWorklogs(res.data))
        .catch((err) => console.log('Worklogs fetch error: ', err))
    }

    if (!request) {
        return null
    }

    return (
      <Fragment>

        <div className="toolbar" id="kt_toolbar">
          <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
              <div data-kt-swapper="true" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                  <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Request Details</h1>
                  <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                  <small className="text-muted fs-7 fw-bold my-1 ms-1">#XRS-45670</small>
              </div>
              <div className="d-flex align-items-center py-1">
                  <div className="me-4">
                      <span className="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="black"></path>
                          </svg>
                      </span>
                      <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_6155ac804a1c2">
                          <div className="px-7 py-5">
                              <div className="fs-5 text-dark fw-bolder">Filter Options</div>
                          </div>
                          <div className="separator border-gray-200"></div>
                          <div className="px-7 py-5">
                              <div className="mb-10">
                                  <label className="form-label fw-bold">Status:</label>
                                  <div>
                                      <select className="form-select form-select-solid" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_6155ac804a1c2" data-allow-clear="true">
                                          <option></option>
                                          <option value="1">Approved</option>
                                          <option value="2">Pending</option>
                                          <option value="2">In Process</option>
                                          <option value="2">Rejected</option>
                                      </select>
                                  </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                  <button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                                  <button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <Link to="/requests/create" className="btn btn-primary">Create</Link>
              </div>
          </div>
        </div>

        <div id='kt_post' className='post d-flex flex-column-fluid'>
          <div id='kt_content_container' className='container'>
            <div className='card card-custom'>
              <div className="card-body">
                <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mt-10 fs-6'>
                  <li className='nav-item'>
                    <a className='nav-link active' data-bs-toggle='tab' href='#details'>
                      Details
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#worklogs'>
                      Worklogs
                    </a>
                  </li>
                </ul>
                <div className='tab-content'>
                  <div className='tab-pane fade show active' id='details' role='tabpanel'>
                    <div className='d-flex rounded border p-10 mt-10 w-100'>
                      <RequestForm initialValues={request} onSubmit={handleFormSubmit} />
                    </div>
                  </div>
                  <div className='tab-pane fade' id='worklogs' role='tabpanel'>
                    <div className='d-flex justify-content-end mt-5'>
                      <Link to={`/requests/${requestId}/worklogs/create`}>Add Worklog</Link>
                    </div>
                    <div className='mt-5'>
                      <WorklogsTable data={worklogs} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
}

export default RequestEdit