import { FC, useState } from "react";
import { Alert, Container, Row, Col } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

import { LayoutProvider } from '../../../_metronic/layout/core/LayoutProvider'
import { BlankLayout } from '../../../_metronic/layout/BlankLayout'
import { LoginForm, ILogin } from './components/LoginForm'
import { login } from '../../services/authService'
import {toAbsoluteUrl} from "../../../_metronic/helpers";

const Login: FC = () => {
    const [errorMessage, setErrorMessage] = useState(null);
    const history = useHistory()

    const handleSubmit = (values: ILogin) => {
        login(values)
            .then((res) => {
                localStorage.setItem('access_token', res.data.access_token)

                history.push('/dashboard')
            })
            .catch((err) => {
                setErrorMessage(err.response.data.error_description)
            })
    }

    return (
        <LayoutProvider>
            <BlankLayout>
                <Container>
                    <Row>
                        <Col md="4" className="offset-4 mt-5">
                            <Row>
                                <div className="login-box">
                                    <Col md="12" className="text-center">
                                    <img
                                        src={toAbsoluteUrl('/media/logos/logow7.png')}
                                        className='align-self-end'
                                        alt='Workforce-7'
                                    />
                                    </Col>
                                    <Col md="12" className="">
                                        { errorMessage && (
                                            <Alert variant="danger">
                                                <span>{ errorMessage }</span>
                                            </Alert>
                                        )}
                                        <LoginForm onSubmit={handleSubmit} />
                                    </Col>

                                    <Col md="12">
                                        <Link to='/forgot-password' className="float-end">Forgot password</Link>
                                    </Col>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </BlankLayout>
        </LayoutProvider>
    )
}

export default Login

