import React from 'react'
const Content: React.FC = ({children}) => (

      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        {children}
      </div>

)

export {Content}
